$(document).ready(function() {
    console.log('Document Ready 10/2019');


    $("[id^='dp_'][id$='_fechaPedido']").change( function(){
        console.log($(this).data("DateTimePicker").viewDate.format('DD'));
        console.log($(this).data("DateTimePicker").viewDate.format('MM'));
        console.log($(this).data("DateTimePicker").viewDate.format('YYYY'));

        mespedido=$(this).data("DateTimePicker").viewDate.format('MM');
		aniopedido=$(this).data("DateTimePicker").viewDate.format('YYYY');
		semanapedido=$(this).data("DateTimePicker").viewDate.format('WW');

		$("[id$='_fechaaamm']").val(aniopedido+mespedido);
	    $("[id$='_semanaDelEvento']").val(aniopedido+semanapedido);

    });

    $('#btnBusquedaOrdenar').click(function() {
        $.ajax({
            method: "POST",
            url: Routing.generate('getrecetas'),
            data: { tipo_receta: $(this).val() }
        })
        .done(function( recetas ) {
            recetas = $.parseJSON(recetas);
            $('#tabla_recetas tbody').html('');
            $.each(recetas, function(index, value){
                tr = '<tr style=""><td id="trrecetas_'+value.id+'" rel="'+value.id+'" class="sonata-ba-td-sdec3b9a452_recetaSubRecetas-_delete control-group">'+value.nombre+'</td></tr>';
                $('#tabla_recetas tbody').append(tr);
                refreshLoadRecetas();
            });
        });
    });

    

});
