/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
require('../logo_galindez.jpg');
// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.css');
require('../css/custom_admin.css');
require('../js/custom_admin_pedido.js');
// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
var $ = require('jquery');


console.log('Hello Webpack Encore! Edit me in assets/js/app.js');
